button {
  @include var(background, button-background);
  border: 0 none;
  border-radius: .2em;
  @include var(color, button-text);
  font-family: inherit;
  font-size: inherit;
  padding: .5em .75em;

  &:hover,
  &:focus {
    @include var(background, button-background--hover);
  }
}

.button {
  align-items: center;
  @include var(background-color, button-background-alt);
	border-radius: 5px;
	border: 0 none;
	cursor: pointer;
  display: inline-flex;
	font-size: inherit;
	overflow: hidden;
  padding-left: 1.5rem;
  text-decoration: none;
	transition: background .2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &,
  &:visited,
  &:hover,
  &:visited:hover {
    @include var(color, button-text);
  }

  &:active {
    transform: translateY(1px);
  }

	&__icon {
		display: block;
		height: 4rem;
		width: 4rem;
    margin-left: 1.5rem;
    @include var(background-color, button-icon-shadow-color);
    padding: .5rem;
	}
}