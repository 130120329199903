.tags {
  font-size: .8em;
  margin: 0;

  &__icon {
    margin-right: .25em;
    vertical-align: middle;
  }

  &__item {
    margin-left: .25em;
  }
}