.breadcrumbs {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;

    &::after {
      content: ' / ';
    }

    &:last-child::after {
      content: none;
    }
  }
}
