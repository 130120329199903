.footer {
  padding-bottom: 2em;
  position: relative;
  text-align: center;

  &::before {
    @include fancy-border();

    @media (min-width: #{$max-line-length + 2em}) {
      left: 0;
      right: 0;
      width: $max-line-length;
    }
  }

  &__social {
    @include var(background-color, background-alt);
    display: inline-flex !important;
    @include var(box-shadow, 0 0 0 10px, background-alt);
    margin-bottom: 1em;

    @media (prefers-color-scheme: dark) { // @TODO
      --c-social-link: rgba(63, 63, 63, .8);
      --c-social-link--hover: rgba(63, 63, 63, 1);
    }
  }
}
