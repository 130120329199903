@use "sass:math";

.header {
  $block: &;

  @include var(background-color, header-background);
  border-bottom: $header-border-size solid;
  @include var(border-bottom-color, header-border);
  @include var(color, header-text);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  padding: $header-padding $header-padding calc(#{$header-padding} - #{$header-border-size});

  @media (min-width: $bp-x-small) {
    position: relative;

    .social-links {
      justify-content: flex-start;
    }
  }

  &--home {
    text-align: center;

    @media (min-width: $bp-x-small) {
      text-align: left;
    }
  }

  &__inner {
    margin: 0 auto;
    max-width: $max-line-length;
    width: 100%;
    z-index: 1;

    @media (min-width: $bp-nav-move) {
      position: relative;
    }

    #{$block}--home & {
      @media (min-width: $bp-x-small) {
        padding-bottom: 1.5em;
      }

      @media (min-width: $bp-nav-move) {
        max-width: $header-max-width;
      }
    }
  }

  &__logo {
    @include var(background, logo-background);
    border-radius: 50%;
    display: inline-block;
    font-size: 38px;
    height: $logo-size;
    left: $logo-spacing;
    line-height: $logo-size * 1.04;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: $logo-spacing * -1;
    transform: rotate(-7.5deg) scale(1.01);
    width: $logo-size;
    z-index: 2;

    @media (prefers-color-scheme: dark) {
      @include var(box-shadow, 0 0 2px, default-shadow-color);
    }

    &,
    &:visited,
    &:hover,
    &:visited:hover {
      @include var(color, logo-text-color);
    }

    @media (min-width: $bp-x-small) {
      top: ($logo-size - $logo-spacing) * -1;
    }

    @media (min-width: $bp-logo-move) {
      left: auto;
      right: $nav-link-size * 0.5;
      top: math.div($logo-size, -2);
    }

    @media (min-width: $bp-nav-move) {
      left: 100%;
      right: auto;
      top: calc(100% - #{$logo-size * 0.5});
    }

    #{$block}--home & {
      @media (min-width: $bp-nav-move) {
        top: 7px;
      }
    }
  }

  &__title {
    margin: 1em 0 1rem;
  }

  &__tagline {
    font-size: 2.4rem;
    margin-top: 1rem;
  }
}
