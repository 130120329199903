code,
pre {
  border: 1px solid;
  @include var(border-color, code-border-color);
  border-radius: .4em;
  @include var(background, code-background);
}

code {
  display: inline-block;
  font-size: .9em;
  padding: .1em 0 .1em;
  margin: 1px 0;

  &:before,
  &:after {
    content: "\00a0";
    letter-spacing: -.2em
  }
}

pre {
  padding: .75em 1em;

  > code {
    display: block;
    border: 0;
    margin: 0;

    &:before,
    &:after {
      content: none;
    }
  }
}
