.banner {
  @include var(background-color, banner-background-color);
  @include var(border-bottom, 1px solid, banner-border-color);
  bottom: 100%;
  display: none;
  height: 7rem;
  padding: 0 .75em;
  position: absolute;
  width: 100%;

  @media (min-width: #{$bp-medium}) {
    display: block;
  }

  @media (min-width: #{$bp-nav-move}) {
    margin-bottom: 2rem;
  }

  &--hidden {
    display: none;
  }

  &__inner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 44em;
  }

  &__close {
    align-items: center;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    font-size: .9em;
    padding: 0;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  &__close-icon {
    @include var(background-color, banner-close-button);
    border-radius: 5px;
    color: white;
    display: inline-block;
    height: 3rem;
    line-height: 3rem;
    margin-right: .5em;
    padding: .5rem;
    text-align: center;
    vertical-align: middle;
    width: 3rem;
  }

  &__view-code {
    font-size: .9em;
    margin: 0;
  }

  &__button {
    margin-left: 1.5rem;
  }
}

.body-has-banner {
  @media (min-width: #{$bp-medium}) {
    transform: translateY(7rem);
  }

  @media (min-width: #{$bp-nav-move}) {
    transform: translateY(9rem);
  }
}
