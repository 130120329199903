html {
	box-sizing: border-box;
	display: flex;
	min-height: 100%;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	@include var(background-color, background-alt);
	display: flex;
	flex-flow: column;
	flex: 1 auto;
	margin: 0;
}

main {
	flex: 1 auto;
	padding-bottom: 5em;
	padding-left: 1em;
	padding-right: 1em;
	padding-top: 2em;
}

svg {
	fill: currentColor;
}
