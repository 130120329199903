.nav-bar {
  display: flex;
  padding-left: ($logo-size * 1.01) + ($logo-spacing * 2);
  width: 100%;

  @media (min-width: $bp-logo-move) {
    padding-left: $logo-spacing;
  }

  @media (min-width: $bp-nav-move) {
    flex-flow: column nowrap;
    margin-right: 18em;
    overflow: hidden;
    padding: 2px 0;
    position: absolute;
    right: 50%;
    top: 160px;
    width: 90px;
    z-index: 2;

    &::after {
      @include var(box-shadow, 0 0 $hero-shadow-size 1px, default-shadow-color);
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
    }

    &--home {
      margin-right: 19em;
      top: 60px;
    }
  }

  &__link {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: $nav-link-size;
    justify-content: center;
    position: relative;
    text-decoration: none;
    width: $nav-link-size;

    &,
    &:visited {
      @include var(color, nav-link);

      @media (prefers-color-scheme: dark) and (max-width: #{$bp-nav-move - 1px}) {
        @include var(color, link);
      }
    }

    &:focus,
    &:hover,
    &:visited:hover {
      @include var(color, nav-link--hover);

      @media (prefers-color-scheme: dark) and (max-width: #{$bp-nav-move - 1px}) {
        @include var(color, link--hover);
      }
    }

    @media (min-width: $bp-nav-move) {
      border-radius: 8px 0 0 8px;
      height: 90px;
      margin-bottom: 4px;
      margin-top: 4px;
      overflow: hidden;
      width: 90px;

      &,
      &:visited {
        @include var(background-color, nav-link);
        @include var(color, nav-link-text);
      }

      &:focus,
      &:hover,
      &:visited:hover {
        @include var(background-color, nav-link--hover);
        @include var(color, nav-link-text);
      }

      &--active {
        border-top-left-radius: 22px;
      }

      &--active::before {
        border: 8px solid;
        @include var(border-color, nav-link-page-corner);
        border-radius: 0 0 8px 0;
        box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, .4); // @TODO
        content: '';
        display: block;
        height: 0;
        left: 0;
        position: absolute;
        top: 0;
        width: 0;
      }
    }
  }

  &__link-text {
    display: block;
    font-size: 18px;
    text-align: center;
  }
}
