.blog-post,
.blog-list {
  h1 {
    margin-top: 0;
  }
}

.blog-post {
  &__header {
    margin-bottom: 4rem;
    padding-bottom: 1em;
    padding-top: 4.6rem;
    position: relative;

    @media (min-width: #{$bp-small}) {
      padding-top: 0;
    }

    &::after {
      @include fancy-border();
      left: 0;
      right: 0;
      top: 100%;
      z-index: 0;
    }
  }

  &__title {
    font-size: 3.4rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 2rem;

    @media (min-width: 600px) {
      font-size: 4rem;
    }
  }

  &__footer {
    margin-top: 7rem;
  }
}

.blog-list {
  &__item {
    position: relative;

    & + & {
      margin-top: 5rem;
      padding-top: 5rem;

      &::after {
        @include fancy-border();
        left: 0;
        right: 0;
        top: 0;
      }
    }
  }

  &__heading {
    margin-top: 0;
  }
}

.markdown {
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  ul {
    padding-left: 1.4em;

    @media (min-width: #{$bp-large}) {
      padding-left: 2em;
    }
  }

  ol {
    list-style-position: inside;
    padding-left: 0;

    > li {
      margin: 1em 0;

      &::marker {
        font-weight: bold;
      }
    }
  }

  pre {
    overflow-x: auto;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 1.2em 0 .8em;
  }

  blockquote {
    @include var(border-left, 4px solid, blockquote-border-color);
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 1.4em;
    margin-left: 0;
    padding: .5em 0 .5em 1.2em;

    > p:first-child {
      margin-top: 0;
    }

    > p:last-child {
      margin-bottom: 0;
    }

    &.excerpt {
      @include var(border-left-color, blockquote-border-color--excerpt);
      font-size: 1.1em;
    }
  }

  q {
    font-style: italic;

    &::before {
      content: open-quote;
    }

    &::after {
      content: close-quote;
    }
  }
}
