$bounce-easing: cubic-bezier(.5, -.35, .50, 1.35);

$avatar-transition-duration: .5s;
$avatar-transition-easing: $bounce-easing;

$header-padding: 1em;
$header-border-size: 10px;
$header-max-width: 33em;

$logo-size: 100px;
$logo-spacing: 10px;

$nav-bar-size: 60px;
$nav-link-size: 80px;

$max-line-length: 33em;

$bp-x-small: 460px;
$bp-small: 600px;
$bp-medium: 750px;
$bp-large: 850px;
$bp-x-wide: 1000px;

$bp-nav-move: $bp-x-wide;
$bp-nav-wide: $bp-x-wide + 200px;
$bp-logo-move: $bp-small;

$hero-max-height: 672px;
$hero-max-width: 1920px;
$hero-padding-top: 20vw;
$hero-post-gutter-x: 1.5em;
$hero-post-gutter-y: .5em;
$hero-shadow-height: 16vw;
$hero-shadow-max-height: 380px;
$hero-shadow-min-height: 150px;
$hero-shadow-size: 7px;
$hero-smallest-overlap-width: 640px;
$hero-target-height: 35vw;