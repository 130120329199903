@use "sass:math";

.has-hero {
  main {
    padding-top: $hero-target-height;
    position: relative;

    @media (min-width: #{$hero-smallest-overlap-width}) {
      padding-top: $hero-padding-top;
    }

    @media (min-width: #{$hero-max-width}) {
      padding-top: $hero-max-height * math.div($hero-padding-top, $hero-target-height);
    }
  }

  .nav-bar {
    @media (min-width: #{$hero-smallest-overlap-width}) {
      top: $hero-padding-top;
    }

    @media (min-width: 1000px) {
      margin-right: math.div($max-line-length, 2) + $hero-post-gutter-x;
      margin-top: 120px;
    }

    @media (min-width: #{$hero-max-width}) {
      top: $hero-max-height * math.div($hero-padding-top, $hero-target-height);
    }
  }

  .blog-post {
    @include var(background-color, background-alt);
    @include var(box-shadow, 0 $hero-post-gutter-y 0 $hero-post-gutter-x, background-alt);
    padding-top: .75em;

    @media (min-width: #{$hero-smallest-overlap-width}) {
      padding-top: 0;
      position: relative;

      &::before {
        @include var(box-shadow, 0 $hero-post-gutter-y $hero-shadow-size $hero-post-gutter-x, default-shadow-color);
        content: '';
        display: block;
        height: $hero-shadow-height;
        max-height: $hero-shadow-max-height;
        min-height: $hero-shadow-min-height;
        position: absolute;
        width: 100%;
        z-index: -1;

        @media (min-width: #{$bp-x-wide}) {
          min-height: 200px;
        }
      }
    }
  }
}

.hero {
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: $hero-target-height;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;

  @media (min-width: #{$hero-max-width}) {
    background-size: contain;
    height: $hero-max-height;
  }

  &__aria-img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__attribution {
    color: rgba(255, 255, 255, .9) !important; // @TODO
    display: inline-block;
    font-size: .7em;
    padding: .5em;
    position: absolute;
    right: 1em;
    text-decoration: none;
    text-shadow: 0 0 2px rgba(0, 0, 0, .80); // @TODO
    top: 1em;

    @media (min-width: #{$hero-smallest-overlap-width}) {
      top: 2em;
      right: 2em;
    }
  }

  %attribution-els {
    display: inline-block;
    transition: opacity .25s;
    vertical-align: middle;
  }

  &__attribution:focus %attribution-els,
  &__attribution:hover %attribution-els {
    opacity: 1;
  }

  &__attribution-icon {
    @extend %attribution-els;
    opacity: .6;
  }

  &__attribution-text {
    @extend %attribution-els;
    opacity: 0;

    &:hover {
      text-decoration: underline;
    }
  }
}
