// Using native system fonts https://css-tricks.com/snippets/css/system-font-stack/
@font-face {
	font-family: system-ui-fallback;
	font-style: normal;
	font-weight: 300;
	src: local(".SFNSText-Light"),
		local(".HelveticaNeueDeskInterface-Light"),
		local(".LucidaGrandeUI"),
		local("Segoe UI Light"),
		local("Ubuntu Light"),
		local("DroidSans"),
		local("Helvetica Neue"),
		local("Tahoma");
}

html {
	font-size: 10px;
}

body {
	@include fluid-type(
		$min-vw: 320px,
		$max-vw: 1000px,
		$min-font-size: 16px,
		$max-font-size: 20px
	);

	@include var(color, body-text);
	font-family: system-ui, system-ui-fallback, Roboto, sans;
	line-height: 1.6;
}

.heading {
	font-weight: bold;
	margin-bottom: .5em;
	margin-top: .5em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;

	&--alpha {
		font-size: 3rem;

		@media (min-width: #{$bp-small}) {
			font-size: 3.6rem;
		}
	}

	&--beta {
		font-size: 2.4rem;

		@media (min-width: #{$bp-small}) {
			font-size: 3.2rem;
		}
	}
}

h1, h2, h3, h4, h5, h6 {
	@extend .heading;
}

h1 {
	@extend .heading--alpha;
}

a {
	@include var(color, link);
	transition: .1s color .1s;

	&:visited {
		@include var(color, link--visited);
	}

	&:focus,
	&:hover {
		@include var(color, link--hover);
	}

	&:visited:hover {
		@include var(color, link--visited-hover);
	}
}

.body-copy {
	margin-left: auto;
	margin-right: auto;
	max-width: $max-line-length;

	section + section {
		margin-top: 3em;
	}
}

.intro {
	font-size: 1.05em;
	font-weight: bold;
}
